import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { axiosPrivate } from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import Sidebar from "../Sidebar";
const CUSTOMER_URL = "/customers";

const Updatelead = () => {
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({
    sales_id: "",
    lead_id_gt: "",
    lead_id_lt: "",
    newSales_id: "",
    // lead_status: "",
  });
  useAxiosPrivate();

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setCustomer({ ...customer, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.put("/customers", customer, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      window.alert(response?.data?.message);
    } catch (err) {
      window.alert(err?.response?.data?.message);
    }

    // const data = await response.json();

    // if (res.status === 422 || !data) {
    //   window.alert("Oops! Something went wrong... Failed");
    // } else {
    //   window.alert("Lead creation Successful");
    //   // navigate("/login");
    //   // history.push("/login");
    // }
  };

  return (
    <>
      <section className="overflow-hidden">
        <div
          className="container px-4 px-md-5 text-center"
          style={{ marginTop: "165px" }}
        >
          <div
            className="card bg-glass signinbox"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <h1
              style={{ textAlign: "center", color: "black", marginTop: "15px" }}
            >
              Update Lead
            </h1>
            <div className="card-body px-4 px-md-5 ">
              <form
              // onSubmit={handleSubmit}
              >
                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="sales_id"
                      id="sales_id"
                      autoComplete="off"
                      value={customer.sales_id}
                      onChange={handleInputs}
                      placeholder="Current Sales ID"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="lead_id_gt"
                      id="lead_id_gt"
                      autoComplete="off"
                      value={customer.lead_id_gt}
                      onChange={handleInputs}
                      placeholder="Lead ID Greater Than"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="lead_id_lt"
                      id="lead_id_lt"
                      autoComplete="off"
                      value={customer.lead_id_lt}
                      onChange={handleInputs}
                      placeholder="Lead ID Less Than"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-2">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="newSales_id"
                      id="newSales_id"
                      autoComplete="off"
                      value={customer.newSales_id}
                      onChange={handleInputs}
                      placeholder="New Sales id"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  name="signup"
                  id="signup"
                  value="register"
                  onClick={PostData}
                  className="btn btn-primary btn-block mb-4"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Updatelead;
