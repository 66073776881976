import { getAllCustomers, getAllDisposition } from "../../api/customer";
import { getAllUsers } from "../../api/user";
import { getAllReports } from "../../api/report";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Oval } from "react-loader-spinner";
function Reports() {
  const [report, setReport] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useAxiosPrivate(); // Very Important to set Authoritation Header Token

  useEffect(() => {
    loadAllReports();
  }, []);

  const loadAllReports = async () => {
    let response = await getAllReports();
    setReport(response?.data);
    setIsLoading(false);
  };

  const Salesreport = () =>
    report?.map((report, index) => {
      return (
        <tr key={index}>
          <td>{report.name}</td>
          <td>{report.svp}</td>
          <td>{report.svd}</td>
          <td>{report.mp}</td>
          <td>{report.md}</td>
        </tr>
      );
    });

  return (
    <>
      {isLoading ? (
        <>
          <section className="main-content main-content-report">
            <div className="row" style={{ backgroundColor: "#f4f7fa" }}>
              <h1 className="text-center mb-5">Reports</h1>
              <Oval
                height={120}
                width={700}
                color="#17a2b8"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#17a2b8"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          </section>
        </>
      ) : (
        <section className="main-content main-content-report">
          <div className="row" style={{ backgroundColor: "#f4f7fa" }}>
            <h1 className="text-center mb-5">Reports</h1>

            <div className="table-responsive">
              <table className="table table-bordered table-hover text-center">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">SV Planned</th>
                    <th scope="col">SV Done</th>
                    <th scope="col">Meeting Planned</th>
                    <th scope="col">Meeting Done</th>
                  </tr>
                </thead>
                <tbody>
                  <Salesreport />
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Reports;
