export const allUsers = (users) => {
  return (dispatch) => {
    dispatch({
      type: "allusers",
      payload: users,
    });
  };
};
export const loggedInuser = (loggedinuser) => {
  return (dispatch) => {
    dispatch({
      type: "loggedinuser",
      payload: loggedinuser,
    });
  };
};
export const allCustomers = (customers) => {
  return (dispatch) => {
    dispatch({
      type: "allcustomers",
      payload: customers,
    });
  };
};
