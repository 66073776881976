import Register from "./components/Register";
import "./App.css";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import Missing from "./components/Missing";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import AdminDashboard from "./pages/AdminDashboard";
// Admin components

import AddMember from "./pages/AddMember";
import CustomerProfile from "./pages/CustomerProfile";
import LeadAll from "./components/leads/LeadAll";
import LeadCreate from "./components/leads/LeadCreate";
import LeadExcel from "./components/leads/LeadExcel";
import LeadUpdate from "./components/leads/LeadUpdate";

import Reports from "./pages/reports/Reports";
import Navbar from "./components/Nav";

import useAuth from "./hooks/useAuth";
import Sidebar from "./components/Side";
import EmpDashboard from "./pages/EmpDashboard";
import EmpLeadAll from "./components/leads/EmpLeadAll";

function App() {
  const { auth } = useAuth();
  console.log(`useAuth -> ${auth.accessToken}`);
  return (
    <>
      {auth?.accessToken ? <Sidebar /> : <Navbar />}

      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          {/* <Route path="/addmember" element={<AddMember />} /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          {/* we want to protect these routes */}

          <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={["FOS", "TL", "Director"]} />}
            >
              <Route
                path="/"
                element={
                  auth?.roles === "FOS" ? <EmpDashboard /> : <Dashboard />
                }
              />
              <Route
                path="/leads"
                element={auth?.roles === "FOS" ? <EmpLeadAll /> : <LeadAll />}
              />
              <Route path="/leadcreate" element={<LeadCreate />} />
              <Route
                path="/leadexcel"
                element={
                  auth?.roles === "FOS" ? <Unauthorized /> : <LeadExcel />
                }
              />
              <Route path="/reports" element={<Reports />} />
              {/* <Route path="/addmember" element={<AddMember />} /> */}
            </Route>

            <Route element={<RequireAuth allowedRoles={"Admin"} />}>
              <Route path="/" element={<Navigate replace to="/admin" />} />
              <Route exact path="/admin" element={<AdminDashboard />} />
              <Route path="/leadcreate" element={<LeadCreate />} />
              <Route path="/leads" element={<LeadAll />} />
              <Route path="/addmember" element={<AddMember />} />
              <Route path="/leadexcel" element={<LeadExcel />} />
              <Route path="/leadupdate" element={<LeadUpdate />} />
              <Route
                path="/customerprofile/:id"
                element={<CustomerProfile />}
              />
            </Route>
          </Route>
          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
