import axios from "axios";
import { axiosPrivate } from "./axios";

const controller = new AbortController();

export const registerUser = async (param) => {
  return await axiosPrivate.post("/user", param, {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
};

export const getAllUsers = async () => {
  return await axiosPrivate.get("/user", {
    signal: controller.signal,
  });
};

export const getUserById = async (param) => {
  return await axios.get(`/user/${param}`);
};
