import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import "../../assets/css/mycss.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

import moment from "moment";
const date = moment().format("DD MMM YYYY hh:mm:ss A");
import { useDispatch, useSelector } from "react-redux";
import SelectUser from "./components/SelectUser";
import "../../api/customer";
import { getAllCustomers, getCustomerById } from "../../api/customer";
import { getAllUsers } from "../../api/user";
import Disposition from "../../pages/Disposition";
import ReactPaginate from "react-paginate";

function LeadAll() {
  const [users, setUser] = useState([]);
  const [lead_id, setLead] = useState([]);
  const [count, setCount] = useState(0);
  const [customers, setCustomer] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const customerDispo = async (e) => {
    setCount(count + 1);
    setLead(e.target.value);
  };

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const displayData = customers
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    .map((data, index) =>
      users?.map((singleuser) => {
        if (singleuser.user_id == data.sales_id) {
          if (data.disposition_status == true) {
            return (
              <tr className="vertical-align: middle;" key={index}>
                <th className="vertical-align: middle; " scope="row">
                  {data.lead_id}
                </th>
                <td className="vertical-align: middle;">{data.name}</td>
                <td className="vertical-align: middle;">{data.phone}</td>

                <td className="vertical-align: middle;">
                  <b style={{ color: "#8b8bfa" }}>Source : </b>
                  {data.project_source} <br />
                  <b style={{ color: "#52be73" }}>Required : </b>
                  {data.project_required}
                </td>
                <td className="vertical-align: middle;">{singleuser.name}</td>
                <td className="vertical-align: middle;">
                  <button
                    type="button"
                    className="btn btn-info"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    value={data.lead_id}
                    onClick={customerDispo}
                  >
                    Add Disposition
                  </button>

                  {/* <NavLink
                    // to={`/customerprofile/${data.lead_id}`}
                    to={"#"}
                  >
                    <button className="btn btn-secondary mx-2">
                      Add Profile
                    </button>
                  </NavLink>

                  <NavLink
                    // to={`/customerprofile/${data.Lead_id}`}
                    to={"#"}
                  >
                    <button className="btn btn-primary">Get Info</button>
                  </NavLink> */}
                </td>
              </tr>
            );
          } else
            return (
              <tr className="vertical-align: middle;" key={index}>
                <th className="vertical-align: middle; freshleads" scope="row">
                  {data.lead_id}
                </th>
                <td className="vertical-align: middle;">{data.name}</td>
                <td className="vertical-align: middle;">{data.phone}</td>

                <td className="vertical-align: middle;">
                  <b style={{ color: "#8b8bfa" }}>Source : </b>
                  {data.project_source} <br />
                  <b style={{ color: "#52be73" }}>Required : </b>
                  {data.project_required}
                </td>
                <td className="vertical-align: middle;">{singleuser.name}</td>
                <td className="vertical-align: middle;">
                  <button
                    type="button"
                    className="btn btn-info"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    value={data.lead_id}
                    onClick={customerDispo}
                  >
                    Add Disposition
                  </button>

                  {/* <NavLink
                    // to={`/customerprofile/${data.lead_id}`}
                    to={"/"}
                  >
                    <button className="btn btn-secondary mx-2">
                      Add Profile
                    </button>
                  </NavLink>

                  <NavLink
                    // to={`/customerprofile/${data.Lead_id}`}
                    to={"/"}
                  >
                    <button className="btn btn-primary">Get Info</button>
                  </NavLink> */}
                </td>
              </tr>
            );
        }
      })
    );

  const pageCount = Math.ceil(customers.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const navigate = useNavigate();
  const { id } = useParams();

  const allUsersdata = useSelector((state) => state.users);
  const allUsers = Array.from(allUsersdata);
  // debugger;
  const axiosPrivate = useAxiosPrivate();

  const controller = new AbortController();

  useEffect(() => {
    loadUser();
    loadCustomer();
  }, []);

  const loadCustomer = async () => {
    let response = await getAllCustomers();

    let res = response.data.slice(0, 10000);
    setCustomer(res);
  };

  const loadUser = async () => {
    let response = await getAllUsers();
    setUser(response.data);
  };

  const loadCustomerById = async (id) => {
    let response = await getCustomerById(id);
    setCustomer(response.data);
  };

  const sendData = async (e) => {
    debugger;
  };

  const filterUser = async (e) => {
    var value = e.target.value;

    if (value == "All") {
      loadCustomer();
    } else {
      var value = JSON.parse(value);
      var id = value.id;
      var role = value.role;
      loadCustomerById(id);
    }
  };

  return (
    <>
      <Disposition lead_id={lead_id} count={count} />
      <section className="main-content">
        <h2 className="text-center mb-2 mt-2">ALL LEADS</h2>
        {/* User Selection Dropdown 1st */}

        <SelectUser
          users={users}
          // userInfor={userInfor}
          filterUser={filterUser}
          sendData={sendData}
        />
        {/* //concatenate
    //send object */}
        <div className="table-responsive" style={{ marginLeft: "15px" }}>
          <table className="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile No.</th>
                <th scope="col">Project</th>
                <th scope="col">Sales Assigned</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="vertical-align: middle;">{displayData}</tbody>
          </table>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />

          <p className="block">
            Displaying {pageNumber * 10 + 1}-
            {(pageNumber + 1) * 10 < customers.length
              ? (pageNumber + 1) * 10
              : customers.length}{" "}
            of {customers.length} results
          </p>
        </div>
      </section>
    </>
    // </div>
    // </div>
  );
}

export default LeadAll;
