import { useEffect, useState, useRef } from "react";
import moment from "moment";
const date = moment().format("DD MMM YYYY hh:mm:ss A");
import { axiosPrivate } from "../api/axios";
import useAuth from "../hooks/useAuth";
import useEffectOnlyOnUpdate from "../hooks/useEffectOnlyOnUpdate";
import { getDispositionById } from "../api/customer";
import { useNavigate } from "react-router-dom";

// *****************************************************

const Disposition = ({ lead_id, count }) => {
  // debugger;
  const myRef = useRef(null);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [getcustomerDisposition, setGetcustomerDisposition] = useState([]);
  const [customerDisposition, setcustomerDisposition] = useState({
    date_created: date,
    user_id: auth.user_id,
    disposition: "",
    comment: "",
  });

  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setcustomerDisposition({ ...customerDisposition, [name]: value });
  };

  async function PostData(e) {
    e.preventDefault();
    try {
      const response = await axiosPrivate.post(
        // `/disposition`,
        `/disposition/${lead_id}`,
        customerDisposition,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      window.alert("Add disposition Successfully Updated");
      myRef.current.click();
    } catch (error) {
      window.alert(`${error} Oops! Something went wrong... Failed`);
    }

    setcustomerDisposition({
      disposition: "",
      comment: "",
    });
  }

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      loadDisposition(lead_id);
    } else {
      didMount.current = true;
    }
  }, [count]);

  const loadDisposition = async (lead_id) => {
    let response = await getDispositionById(lead_id);
    setGetcustomerDisposition(response.data);
  };

  const cleardata = async () => {
    setcustomerDisposition({
      disposition: "",
      comment: "",
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Sales Disposition of Lead Id: {lead_id}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="yondro">
                <div className="part1">
                  <div className="item1">Progress</div>
                  <div className="item2">
                    <span>
                      <span
                        className="fa fa-circle"
                        style={{ color: "green" }}
                      ></span>
                      Initial Contact
                    </span>
                    <span>
                      <span
                        className="fa fa-circle"
                        style={{ color: "gray" }}
                      ></span>
                      Meeting Stage
                    </span>
                    <span>
                      <span
                        className="fa fa-circle"
                        style={{ color: "gray" }}
                      ></span>
                      SV stage
                    </span>
                    <span>
                      <span
                        className="fa fa-circle"
                        style={{ color: "gray" }}
                      ></span>
                      FN stage
                    </span>
                    <span>
                      <span
                        className="fa fa-circle"
                        style={{ color: "gray" }}
                      ></span>
                      Initial contact
                    </span>
                  </div>
                </div>
                <div className="part2">
                  <div className="items1">Disposition</div>
                  <div className="items2">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="disposition"
                      id="disposition"
                      autoComplete="off"
                      value={customerDisposition.disposition}
                      onChange={handleInputs}
                    >
                      <option defaultValue>Open this select menu</option>
                      <option value="Not Interested">Not Interested</option>
                      <option value="Not Recieved">Not Recieved</option>
                      <option value="Call Back">Call Back</option>
                      <option value="Switch off/Out of reach">
                        Switch off/Out of reach
                      </option>
                      <option value="Site Visit Planned">
                        Site Visit Planned
                      </option>
                      <option value="Site Visit Postponed">
                        Site Visit Postponed
                      </option>
                      <option value="Site Visit Done">Site Visit Done</option>
                      <option value="Meeting Planned">Meeting Planned</option>
                      <option value="Meeting Done">Meeting Done</option>
                      <option value="Fn Planned">Fn Planned</option>
                      <option value="Fn Postponed">Fn Postponed</option>
                      <option value="Fn Done">Fn Done</option>
                      <option value="Closure">Closure</option>
                    </select>
                  </div>
                  <div className="items3">Comment</div>
                  <div className="items4">
                    <textarea
                      type="text"
                      name="comment"
                      id="comment"
                      className="comment"
                      autoComplete="off"
                      value={customerDisposition.comment}
                      onChange={handleInputs}
                      placeholder="Comment"
                    ></textarea>
                  </div>
                </div>
                <div
                  className="table-responsive"
                  style={{ maxheight: "160px" }}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Disposition</th>
                        <th scope="col">Comment</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody className="overflow-auto">
                      {getcustomerDisposition?.map((data) => (
                        <tr key={data.date_created}>
                          <td>{data.disposition}</td>
                          <td>{data.comment}</td>
                          <td>{data.date_created}</td>
                          {/* <td>{data.meeting}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                id="dispositionClose"
                data-bs-dismiss="modal"
                ref={myRef}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cleardata}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-info"
                name="submit"
                id="dispositionsubmit"
                value="Submit"
                onClick={PostData}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Disposition;
