// used to export all reducers

import { combineReducers } from "redux";
import userReducer from "./userReducer";
import loggedinuserReducer from "./loggedinuserReducer";
import customerReducer from "./customerReducer";

const reducers = combineReducers({
  users: userReducer,
  loggedinuser: loggedinuserReducer,
  customer: customerReducer,
});

export default reducers;
