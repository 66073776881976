import axios from "axios";
import { axiosPrivate } from "./axios";

const controller = new AbortController();

export const getAllCustomers = async () => {
  return await axiosPrivate.get("/customers", {
    signal: controller.signal,
  });
};

export const getCustomerById = async (param) => {
  return await axiosPrivate.get(`/customers/${param}`, {
    signal: controller.signal,
  });
};

export const getAllDisposition = async () => {
  return await axiosPrivate.get("/disposition", {
    signal: controller.signal,
  });
};

export const getDispositionById = async (param) => {
  return await axiosPrivate.get(`/disposition/${param}`, {
    signal: controller.signal,
  });
};

export const leadCreate = async (param) => {
  return await axiosPrivate.post("/customers", param, {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
};

export const excelLeads = async (param) => {
  return await axiosPrivate.post("/leadexcel", param, {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  });
};

export const deleteLead = async (param) => {
  const res = await axios.delete(`/all_leads/${id}`);
  // const data = await res.json();

  if (res) {
    window.alert("One Lead deleted Successfully");
    loadUser();
  } else {
    // navigate("/login");
    // history.push("/login");
    window.alert("Oops! Something went wrong... Failed");
  }
};
