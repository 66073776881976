import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Sidebar from "../components/Side";
import LeadAll from "../components/leads/LeadAll";
import { actionCreators } from "../state/index";
import { getAllCustomers } from "../api/customer";
import { getAllUsers } from "../api/user";

const Dashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadUser();
    loadCustomer();
  }, []);

  const loadUser = async () => {
    let response = await getAllUsers();
    dispatch(actionCreators.allUsers(response.data));
  };

  const loadCustomer = async () => {
    let response = await getAllCustomers();
    dispatch(actionCreators.allCustomers(response.data));
  };

  return (
    <>
      <LeadAll />
    </>
  );
};

export default Dashboard;
