// import "../assets/css/sidebars.css";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import useLogout from "../hooks/useLogout";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import useAuth from "../hooks/useAuth";
import { Helmet } from "react-helmet";
import LeadAll from "./leads/LeadAll";
import logo from "../assets/img/logo_final.png";
import profilepic from "../assets/img/7TreezRealtorsFavicon.jpg";

// import { useRef } from "react";

function Side() {
  const { auth } = useAuth();

  const [sidebar, setSidebar] = useState(false);
  const [pageHolder, setPageHolder] = useState(false);
  const [heading, setHeading] = useState(false);

  const loggedInuserdata = useSelector((state) => state.loggedinuser);

  const navigate = useNavigate();
  const logout = useLogout();
  const signOut = async () => {
    await logout();
    navigate("/login");
  };

  // ------------------------------------------------------- //
  // Side Navbar Functionality
  // ------------------------------------------------------ //

  const sidebarToggle = () => {
    if (window.outerWidth > 1194) {
      setSidebar((sidebar) => !sidebar);
      setPageHolder((pageHolder) => !pageHolder);
      setHeading((heading) => !heading);
    } else {
      setSidebar((sidebar) => !sidebar);
      setPageHolder((pageHolder) => !pageHolder);
    }
  };

  if (window.outerWidth > 1194) {
    var sidebarToggleCheck = sidebar ? " shrink" : "";
    var pageHolderToggleCheck = pageHolder ? " active" : "";
    var headingToggleCheck = heading ? " mx-lg-2" : "";
  } else {
    var sidebarToggleCheck = sidebar ? " show-sm" : "";
    var pageHolderToggleCheck = pageHolder ? " active-sm" : "";
  }

  return (
    <>
      {/* <!-- Side Navbar --> */}
      <nav className={`side-navbar${sidebarToggleCheck}`}>
        {/* <!-- Sidebar Header    --> */}
        <div className="sidebar-header d-flex align-items-center justify-content-center p-3 mb-3">
          {/* <!-- User Info--> */}
          <div className="sidenav-header-inner text-center">
            <img
              className="img-fluid rounded-circle avatar mb-3"
              src={profilepic}
              alt="person"
            />
            <h2 className="h5 text-white text-uppercase mb-0">{auth?.name}</h2>
            <p className="text-sm mb-0 text-muted">{auth?.post}</p>
          </div>
          {/* Small Brand information, appears on minimized sidebar  */}
          <a className="brand-small text-center" href="#">
            <p className="h2 m-0">7Treez</p>
          </a>
        </div>
        {/* <!-- Sidebar Navigation Menus--> */}
        <span
          className={`text-uppercase text-gray-500 text-sm fw-bold letter-spacing-0 mx-lg-2 heading${headingToggleCheck}`}
        >
          Main
        </span>
        <ul className="list-unstyled">
          <li className="sidebar-item">
            <NavLink className="sidebar-link" end to="/">
              <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                <use xlinkHref="#real-estate-1"> </use>
              </svg>
              Home
            </NavLink>
          </li>
          <li className="sidebar-item">
            <NavLink className="sidebar-link" end to="/reports">
              <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                <use xlinkHref="#survey-1"> </use>
              </svg>
              Reports
            </NavLink>
          </li>
          {auth?.roles === "Admin" ? (
            <>
              <li className="sidebar-item">
                <NavLink className="sidebar-link" end to="/addmember">
                  <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                    <use xlinkHref="#portfolio-grid-1"> </use>
                  </svg>
                  Add Members
                </NavLink>
              </li>

              <li className="sidebar-item">
                <NavLink className="sidebar-link" end to="/leadupdate">
                  <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                    <use xlinkHref="#portfolio-grid-1"> </use>
                  </svg>
                  Update Lead
                </NavLink>
              </li>
            </>
          ) : (
            <></>
          )}

          <ul className="list-unstyled">
            <li className="sidebar-item">
              <NavLink className="sidebar-link" end to="/">
                <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                  <use xlinkHref="#real-estate-1"> </use>
                </svg>
                All Leads
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink className="sidebar-link" end to="/leadcreate">
                <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                  <use xlinkHref="#survey-1"> </use>
                </svg>
                Add Leads
              </NavLink>
            </li>
            {auth?.roles === "FOS" ? (
              <></>
            ) : (
              <li className="sidebar-item">
                <NavLink className="sidebar-link" end to="/leadexcel">
                  <svg className="svg-icon svg-icon-sm svg-icon-heavy me-xl-2">
                    <use xlinkHref="#portfolio-grid-1"> </use>
                  </svg>
                  Bulk Add Leads
                </NavLink>
              </li>
            )}
          </ul>

          <button className="btn btn-danger my-4" onClick={logout}>
            Logout
          </button>
        </ul>
      </nav>

      {/* navbar */}

      <nav className={`navbar page${pageHolderToggleCheck}`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <NavLink className="navbar-brand" end to="/">
                <img src={logo} alt="logo" className="logo" />
              </NavLink>
              <a
                className="menu-btn d-flex align-items-center justify-content-center p-2 sidebaricon"
                id="toggle-btn"
                href="#"
                onClick={(e) => sidebarToggle(e)}
              >
                <svg className="svg-icon svg-icon-sm svg-icon-heavy text-white">
                  <use xlinkHref="#menu-1"> </use>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Side;
