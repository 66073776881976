import { useState } from "react";
import axios from "axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const SelectUser = ({ users, subusers, filterUser, sendData }) => {
  var myName = "Abhilekh";
  return (
    <>
      {/* <button onClick={(e) => sendData(myName)}>Click</button> */}
      <select
        name="users"
        id="users"
        style={{
          width: "165px",
          marginLeft: "25px",
          marginBottom: "10px",
          backgroundColor: "#f2f2f2",
        }}
        onChange={(e) => filterUser(e)}
      >
        <option defaultValue>--Select User--</option>

        {users?.map((x) => {
          return (
            <option
              key={x.user_id}
              value={JSON.stringify({ id: x.user_id, role: x.roles })}
            >
              {x.name}
            </option>
          );
        })}
        <option value={"All"}>All</option>
      </select>

      {/* <select
        name="subusers"
        id="subusers"
        style={{ width: "165px", marginLeft: "12px", marginBottom: "10px" }}
        onChange={(e) => filterUser(e)}
      >
        <option defaultValue>--Select User--</option>

        {subusers?.map((x) => {
          return (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          );
        })}
        <option value={"All"}>All</option>
      </select> */}
    </>
  );
};

export default SelectUser;
