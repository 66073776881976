import { NavLink } from "react-router-dom";
import logo from "../assets/img/logo_final.png";

const Nav = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light navbar-custom">
        <div className="container-fluid">
          <NavLink className="navbar-brand" end to="/">
            <img src={logo} alt="logo" className="logo" />
          </NavLink>
        </div>
      </nav>
    </>
  );
};

export default Nav;
