// import Tags from "../../../../node_modules/bootstrap5-tags/tags";
// import Tags from "https://cdn.jsdelivr.net/gh/lekoala/bootstrap5-tags@master/tags.js";
import axios from "../api/axios";
import { axiosPrivate } from "../api/axios";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import Sidebar from "../components/Sidebar";
const CUSTOMER_URL = "/customers";

const AddMember = () => {
  // Tags.init();
  const navigate = useNavigate();
  const [member, setmember] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    roles: "",
    status: "",
    parent_id: "",
  });

  useAxiosPrivate();

  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;

    setmember({ ...member, [name]: value });
  };

  async function PostData(e) {
    e.preventDefault();
    try {
      const response = await axiosPrivate.post("/user", member, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      window.alert("User Registered Successfully");
    } catch (err) {
      window.alert(`${err} User Registration Failed`);
    }
  }

  return (
    <>
      <section className="overflow-hidden">
        <div
          className="container px-4 px-md-5 text-center"
          style={{ marginTop: "165px" }}
        >
          <div
            className="card bg-glass signinbox"
            style={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <h1
              style={{ textAlign: "center", color: "black", marginTop: "15px" }}
            >
              Add User
            </h1>
            <div className="card-body px-4 px-md-5 ">
              <form
              // onSubmit={handleSubmit}
              >
                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="off"
                      value={member.name}
                      onChange={handleInputs}
                      placeholder="User Name"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      autoComplete="off"
                      value={member.email}
                      onChange={handleInputs}
                      placeholder="User email id"
                    />
                  </div>
                </div>
                <div className="col-xs-3 mb-2">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="off"
                      value={member.phone}
                      onChange={handleInputs}
                      placeholder="User Phone no"
                    />
                  </div>
                </div>
                <div className="col-xs-3 mb-2">
                  <div className="form-outline mb-4">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="off"
                      value={member.password}
                      onChange={handleInputs}
                      placeholder="Your Password"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-2">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="status"
                      id="status"
                      autoComplete="off"
                      value={member.status}
                      onChange={handleInputs}
                      placeholder="Status"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-2">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      name="parent_id"
                      id="parent_id"
                      autoComplete="off"
                      value={member.parent_id}
                      onChange={handleInputs}
                      placeholder="Parent ID"
                    />
                  </div>
                </div>

                <div className="col-xs-3 mb-2">
                  <div className="form-outline mb-4">
                    <select
                      // class="form-select"
                      aria-label="Default select example"
                      value={member.roles}
                      onChange={handleInputs}
                      name="roles"
                      id="roles"
                    >
                      <option>Select Role</option>
                      <option value="Admin">Admin</option>
                      <option value="Director">Director</option>
                      <option value="TL">Team Leader</option>
                      <option value="FOS">FOS</option>
                    </select>
                  </div>
                </div>

                <button
                  type="submit"
                  name="signup"
                  id="signup"
                  value="register"
                  onClick={PostData}
                  className="btn btn-primary btn-block mb-4"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddMember;
