import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from "../api/axios";

import axios from "../api/axios";
const LOGIN_URL = "/auth";

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const [phone, setPhone] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [phone, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post(
        LOGIN_URL,
        JSON.stringify({ phone, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const { post, name, roles, accessToken } = response?.data;
      setAuth({ post, name, phone, pwd, roles, accessToken });
      setPhone("");
      setPwd("");

      if (roles === "Admin") {
        var from = "/admin";
      }

      if (roles === "Director") {
        var from = "/";
      }

      if (roles === "TL") {
        var from = "/";
      }

      if (roles === "FOS") {
        console.log("inside fos");
        var from = "/";
      }

      navigate(from, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const togglePersist = () => {
    setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("persist", persist);
  }, [persist]);

  return (
    <>
      <section className="background-radial-gradient overflow-hidden">
        <div className="container px-4 py-5 px-md-5 text-center">
          <div className="card bg-glass signinbox">
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
            <h1 style={{ textAlign: "center", color: "black" }}>Sign In</h1>
            <div className="card-body px-4 py-5 px-md-5 ">
              <form onSubmit={handleSubmit}>
                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control"
                      ref={userRef}
                      autoComplete="off"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      required
                    />
                    <label className="form-label" htmlFor="form3Example1">
                      Phone no
                    </label>
                  </div>
                </div>

                <div className="col-xs-3 mb-4">
                  <div className="form-outline mb-4">
                    <input
                      type="password"
                      id="form3Example4"
                      className="form-control"
                      onChange={(e) => setPwd(e.target.value)}
                      value={pwd}
                      required
                    />
                    <label className="form-label" htmlFor="form3Example4">
                      Password
                    </label>
                  </div>
                </div>

                <button
                  // type="submit"
                  className="btn btn-primary btn-block mb-4"
                >
                  Sign in
                </button>

                <div className="persistCheck">
                  <input
                    type="checkbox"
                    id="persist"
                    onChange={togglePersist}
                    checked={persist}
                  />
                  <label htmlFor="persist">Trust This Device</label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
