import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";
import thunk from "redux-thunk";
import { applyMiddleware } from "redux";
import { combineReducers } from "redux";

// const persistConfig = {
//   key: "main-root",
//   storage,
// };

// const reducer = combineReducers({
//   reducer: reducers,
// });

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: false,
//  });

// export default store;

const store = configureStore(
  { reducer: reducers },
  {},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk)
);

export default store;
