import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  console.log(`allowedRoles - ${allowedRoles}`);
  console.log(`allowedRoles - ${[auth?.roles]}`);

  console.log(`array role - ${allowedRoles.includes(auth?.roles)}`);
  // console.log(`allowedRoles auth - ${auth?.roles.includes(allowedRoles)}`);

  return allowedRoles.includes(auth?.roles) ? (
    <Outlet />
  ) : auth?.phone ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
