import { useState } from "react";
// import MaterialTable from "material-table";
import * as XLSX from "xlsx";

import { axiosPrivate } from "../../api/axios";
import Sidebar from "../Side";
import { Oval } from "react-loader-spinner";

function Excelleads() {
  const [colDefs, setColDefs] = useState();
  const [Cdata, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const convertToJson = (headers, data) => {
    const rows = [];
    data.forEach((row) => {
      let rowData = {};
      row.forEach((element, index) => {
        rowData[headers[index]] = element;
      });
      rows.push(rowData);
    });
    return rows;
  };

  const importExcel = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      //parse data

      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });
      console.log(`From workBook =  ${workBook}`);

      //get first sheet
      const workSheetName = workBook.SheetNames[0];
      const worksheet = workBook.Sheets[workSheetName];
      //convert to array

      const fileData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = fileData[0];

      const heads = headers.map((head) => ({ title: head, field: head }));
      setColDefs(heads);
      fileData.splice(0, 1);
      setData(convertToJson(headers, fileData));
    };
    reader.readAsBinaryString(file);
  };

  const Postdata = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      const response = await axiosPrivate.post("/leadexcel", Cdata, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      window.alert(`${response?.data?.message}`);
    } catch (err) {
      debugger;
      console.log(err?.response);
      window.alert(
        `Customer Registeration failed with Error - ${err?.response}`
      );
    }
    setIsLoading(false);
  };

  return (
    <>
      <section className="main-content main-content-report">
        <div className="row" style={{ backgroundColor: "#f4f7fa" }}>
          <h1 className="text-center mb-5">Bulk Upload Leads</h1>
          <h4 align="center">Import Data from Excel</h4>
          {isLoading ? (
            <Oval
              height={120}
              width={700}
              color="#17a2b8"
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#17a2b8"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            <>
              <input type="file" onChange={importExcel} />
              <button
                type="button"
                className="btn btn-info"
                name="signup"
                id="signup"
                value="register"
                onClick={Postdata}
                style={{ width: "auto", marginTop: "10px" }}
              >
                Upload Bulk Leads
              </button>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default Excelleads;
