import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LeadAll from "../components/leads/EmpLeadAll";
import { actionCreators } from "../state/index";
import { getAllCustomers } from "../api/customer";

const EmpDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    loadCustomer();
  }, []);

  const loadCustomer = async () => {
    let response = await getAllCustomers();
    dispatch(actionCreators.allCustomers(response.data));
  };

  return (
    <>
      <LeadAll />
    </>
  );
};

export default EmpDashboard;
